import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import axios from "axios"
import { Field, Form, Formik } from "formik"
import { CheckboxWithLabel, TextField } from "formik-material-ui"
import { graphql, navigate } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import * as Yup from "yup"
import BigForm from "../components/big-form"
import CTAButton from "../components/CTAButton"
import Page from "../components/page"
import { buildMeta } from "../helpers"

const ContactSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  company: Yup.string(),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  subject: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
  weeklyTrends: Yup.boolean().required("Required"),
})

export const ContactTemplate = ({
  data: {
    title,
    companyfullname,
    address,
    suite,
    city,
    state,
    zip,
    contactlinks,
  },
}) => {
  return (
    <BigForm
      title={title}
      form={
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            role: "",
            company: "",
            email: "",
            subject: "",
            message: "",
            weeklyTrends: false,
          }}
          validationSchema={ContactSchema}
          onSubmit={(values, { setSubmitting }) => {
            axios
              .post("/api/contact", {
                first_name: values.firstName,
                last_name: values.lastName,
                role: values.role,
                company: values.company,
                email: values.email,
                subject: values.subject,
                message: values.message,
                get_trends: values.weeklyTrends,
              })
              .then(
                () => {
                  navigate("/thank-you")
                },
                () => {
                  setSubmitting(false)
                }
              )
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="firstName"
                    label="First Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="lastName"
                    label="Last Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="role"
                    label="Role"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="company"
                    label="Company"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    component={TextField}
                    name="subject"
                    select
                    label="Subject"
                    fullWidth
                  >
                    <MenuItem value="support">Support</MenuItem>
                    <MenuItem value="sales">Sales</MenuItem>
                    <MenuItem value="accounting">Accounting</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    component={TextField}
                    name="message"
                    label="Message"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    color="primary"
                    component={CheckboxWithLabel}
                    name="weeklyTrends"
                    type="checkbox"
                    Label={{ label: "Get weekly market and price trends" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CTAButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Send message
                  </CTAButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
      info={
        <Box display="flex" justifyContent="center">
          <div>
            <Box mb={5}>
              <Typography variant="h4" color="inherit">
                {companyfullname}
              </Typography>
            </Box>
            <Box mb={3}>
              {address && (
                <Box display="flex" alignItems="center">
                  <Box width="30px">
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                  </Box>
                  <Typography variant="subtitle2" color="inherit">
                    {address}
                  </Typography>
                </Box>
              )}
              {suite && (
                <Box display="flex" alignItems="center">
                  <Box width="30px" />
                  <Typography variant="subtitle2" color="inherit">
                    {suite}
                  </Typography>
                </Box>
              )}
              {city && state && zip && (
                <Box display="flex" alignItems="center">
                  <Box width="30px" />
                  <Typography variant="subtitle2" gutterBottom color="inherit">
                    {city}, {state} {zip}
                  </Typography>
                </Box>
              )}
            </Box>
            {contactlinks.map((el, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                mb={index < contactlinks.length - 1 ? 3 : 0}
              >
                <Box width="30px">
                  <FontAwesomeIcon
                    icon={{
                      prefix: "fas",
                      iconName: el.icon,
                    }}
                    size="lg"
                  />
                </Box>
                {!!el.href ? (
                  <Link display="block" href={el.href} variant="subtitle2">
                    {el.formatted}
                  </Link>
                ) : (
                  <Typography variant="subtitle2" color="inherit">
                    {el.formatted}
                  </Typography>
                )}
              </Box>
            ))}
          </div>
        </Box>
      }
    />
  )
}

const ContactUsPage = ({
  data: { footerData, contactUsPageData, siteData },
  location,
}) => {
  const { seo } = contactUsPageData.frontmatter
  const { siteUrl } = siteData.siteMetadata

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          ...buildMeta(siteData.siteMetadata, seo, null, location.pathname),
          ...(seo.metatags ? seo.metatags : []),
        ]}
      >
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
        {seo.schemamarkup && (
          <script type="application/ld+json">{seo.schemamarkup}</script>
        )}
      </Helmet>
      <Page headerData={{ frontmatter: {} }} footerData={footerData}>
        <ContactTemplate data={contactUsPageData.frontmatter} />
      </Page>
    </React.Fragment>
  )
}

export default ContactUsPage

export const pageQuery = graphql`
  query ContactUsQuery {
    ...PageFragment
    siteData: site {
      siteMetadata {
        siteUrl
        env
      }
    }
    contactUsPageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/contact.md/" }
    ) {
      frontmatter {
        seo {
          title
          description
          keywords
          schemamarkup
          metatags {
            name
            content
          }
        }
        title
        address
        suite
        companyfullname
        city
        state
        zip
        contactlinks {
          formatted
          href
          icon
        }
      }
    }
  }
`
